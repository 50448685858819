<template>
  <div>
    <!-- 表单 -->
    <div class="form">
      <!-- 表单项 -->
      <div class="form-item flex-column-default-start">
        <div class="label flex-row-default-center">
          <span class="iconfont icon-required"></span>
          <span class="iconfont icon-user"></span>
          <span>姓名</span>
        </div>
        <input type="text" v-model="userInfo.name" />
      </div>

      <!-- 表单项 -->
      <div class="form-item flex-column-default-start">
        <div class="label flex-row-default-center">
          <span class="iconfont icon-required"></span>
          <span class="iconfont icon-phone"></span>
          <span>手机号</span>
        </div>
        <input type="text" v-model="userInfo.phoneNumber" />
      </div>

      <!-- 表单项 -->
      <div class="form-item flex-column-default-start">
        <div class="label flex-row-default-center">
          <span class="iconfont icon-required"></span>
          <span class="iconfont icon-email"></span>
          <span>邮箱</span>
        </div>
        <input type="text" v-model="userInfo.email" />
      </div>
    </div>

    <!-- 按钮 -->
    <div class="button-one" @click="handleUpdate">修改</div>
  </div>
</template>

<script>
import { Toast } from 'mint-ui'

export default {
  name: 'UserInfo',

  data () {
    return {
      userInfo: {}
    }
  },

  created () {
    const userInfo = this.$store.state.userInfo
    if (userInfo) {
      this.userInfo = userInfo
    }
  },

  beforeRouteLeave (to, from, next) {
    to.meta.keepAlive = to.path === '/resource-list'
    next()
  },

  methods: {
    // 更新用户信息
    handleUpdate () {
      if (this.userInfo.name === '') {
        Toast('姓名不能为空')
        return
      }
      if (this.userInfo.phoneNumber === '') {
        Toast('手机号不能为空')
        return
      }
      if (this.userInfo.email === '') {
        Toast('邮箱不能为空')
        return
      }
      this.api.updateUserInfo(this.userInfo).then(res => {
        Toast(res.msg)
        this.getUserInfo()
      }).catch(err => {
        if (err.status === 401) this.$store.commit('addLoginCallBack', () => { this.handleUpdate() })
      })
    },
    // 获取用户信息
    getUserInfo () {
      this.api.getUserInfo().then(res => {
        this.userInfo = res.data
        this.$store.commit('setUserInfo', this.userInfo)
      }).catch(err => {
        if (err.status === 401) this.$store.commit('addLoginCallBack', () => { this.getUserInfo() })
      })
    }
  }
}
</script>

<style scoped>
.label > span:last-child {
  margin-left: 3px;
}
.label > span:nth-child(2) {
  color: var(--main-color);
}
</style>
